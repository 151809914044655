import React, { useRef, useState } from 'react';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';
import ArrowUpRightIcon from '@icons/ArrowUpRightIcon';
import ShopCursor from '@icons/ShopCursor';
import cn from 'classnames';
import styles from './styles';

const CONTEXT_HOVER_CLASS = 'has-context-hover';
const CONTEXT_INTERACTION_CLASS = 'has-context-interaction';

const Cursor = () => {
  const ref = useRef();
  const [productAdded, setProductAdded] = useState(false);
  const [isHovering, setHovering] = useState(false);
  const [isHoveringNone, setHoveringNone] = useState(false);
  const [context, setContext] = useState(null);
  const [contextInteracted, setContextInteracted] = useState(null);
  const [isHoverable, setHoverable] = useState(true);

  const onMouseMove = (e) => {
    const html = document.querySelector('html');
    const cursor = ref.current;
    const target = e.target.closest(
      'button:not([disabled]), a:not([disabled]), input:not([disabled]), select:not([disabled]), [data-context], .snipcart-tool-tip__container',
    );

    cursor.style.left = `${e.clientX}px`;
    cursor.style.top = `${e.clientY}px`;
    cursor.style.opacity = `1`;

    setHovering(!!target);

    if (target && target.dataset.context) {
      html.classList.add(CONTEXT_HOVER_CLASS);
      setHoveringNone(false);
      setContext(target.dataset.context);
    } else if (e.target.closest('iframe')) {
      setHoveringNone(true);
      setContext(null);
    } else {
      html.classList.remove(CONTEXT_HOVER_CLASS);
      setContext(null);
      setHoveringNone(false);
    }

    if (target && target.dataset.contextInteracted) {
      html.classList.add(CONTEXT_INTERACTION_CLASS);
      setContextInteracted(target.dataset.contextInteracted);
    } else {
      html.classList.remove(CONTEXT_INTERACTION_CLASS);
      setContextInteracted(null);
    }
  };

  const cursorClickHandler = (e) => {
    const html = document.querySelector('html');

    if (html.classList.contains(CONTEXT_INTERACTION_CLASS)) {
      setProductAdded(true);
      setTimeout(() => {
        setProductAdded(false);
      }, 1500);
    }
  };

  useIsomorphicLayoutEffect(() => {
    if (isHoverable) {
      window.addEventListener('mousemove', onMouseMove);
      return () => window.removeEventListener('mousemove', onMouseMove);
    }
  }, [isHoverable]);

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('click', cursorClickHandler);
    setHoverable(!window.matchMedia('(hover: none)').matches);
  }, []);

  return (
    <div
      className={cn(
        'fixed top-0 left-0 w-screen h-screen pointer-events-none z-70',
        {
          hidden: !isHoverable,
        },
      )}
    >
      <div
        ref={ref}
        className={cn(styles.cursor, {
          [styles.hasInteraction]: contextInteracted !== null,
          [styles.isLoading]: productAdded,
        })}
      >
        {isHoverable && (
          <div
            className={cn(styles.cursorRing, {
              [styles.isHover]: isHovering,
              [styles.isHoverNone]: isHoveringNone,
              [styles.hasContext]: context,
            })}
          />
        )}
        <p className={styles.cursorText}>
          {context === 'link' ? (
            <>
              <ArrowUpRightIcon styled={false} />
              <ShopCursor className={styles.cursorIcon} />
            </>
          ) : (
            context
          )}
        </p>
        {contextInteracted && (
          <p className={styles.cursorText}>{contextInteracted}</p>
        )}
      </div>
    </div>
  );
};

export default Cursor;
