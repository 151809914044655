import React from 'react';

const HeartIcon = () => {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.45 2C22.1781 1.99939 22.899 2.14413 23.5704 2.42575C24.2418 2.70736 24.8502 3.12018 25.36 3.64C26.4104 4.70638 26.9992 6.14316 26.9992 7.64C26.9992 9.13685 26.4104 10.5736 25.36 11.64L15 22.13L4.64002 11.64C3.5896 10.5736 3.00081 9.13685 3.00081 7.64C3.00081 6.14316 3.5896 4.70638 4.64002 3.64C5.15011 3.12055 5.7586 2.70795 6.42993 2.42629C7.10127 2.14464 7.82199 1.99957 8.55002 1.99957C9.27804 1.99957 9.99877 2.14464 10.6701 2.42629C11.3414 2.70795 11.9499 3.12055 12.46 3.64L15 6.24L17.53 3.66C18.0383 3.13399 18.6474 2.71586 19.3209 2.43064C19.9944 2.14542 20.7186 1.99895 21.45 2ZM21.45 2.68285e-06C20.4556 -0.000840038 19.4711 0.196858 18.5541 0.581505C17.6372 0.966151 16.8062 1.53 16.11 2.24L15 3.36L13.89 2.24C13.193 1.53129 12.3618 0.968412 11.445 0.584193C10.5282 0.199974 9.54408 0.00209743 8.55002 0.00209743C7.55596 0.00209743 6.57184 0.199974 5.65503 0.584193C4.73822 0.968412 3.90707 1.53129 3.21002 2.24C1.7919 3.68363 0.997314 5.62636 0.997314 7.65C0.997314 9.67364 1.7919 11.6164 3.21002 13.06L15 25L26.79 13.06C28.2081 11.6164 29.0027 9.67364 29.0027 7.65C29.0027 5.62636 28.2081 3.68363 26.79 2.24C26.0931 1.53091 25.2621 0.967646 24.3453 0.583064C23.4284 0.198482 22.4442 0.000276098 21.45 2.68285e-06Z"
        fill="#FDF5E3"
      />
    </svg>
  );
};

export default HeartIcon;
