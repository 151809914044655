import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';
import cn from 'classnames';

const ScrollInView = ({
  as,
  children,
  className,
  delay,
  triggerOnce,
  animateStartClass,
  animateEndClass,
  ...other
}) => {
  const options = {
    triggerOnce,
  };
  const [domLoaded, setDomLoaded] = useState(false);
  const [delayView, setDelayView] = useState(false);
  const ref = useRef();
  const [inViewRef, inView] = useInView(options);
  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );
  const Wrapper = as;
  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      setDomLoaded(true);
    }
  }, []);
  useIsomorphicLayoutEffect(() => {
    if (inView && delay) {
      setTimeout(() => setDelayView(true), delay);
    }
  }, [inView, delay]);
  return (
    <Wrapper
      ref={setRefs}
      className={cn(
        {
          'is-in-view': domLoaded && delay && delayView,
        },
        {
          'is-in-view': domLoaded && delay === null && inView,
        },
        { [animateStartClass]: animateEndClass && animateStartClass },
        {
          [animateEndClass]:
            animateStartClass && animateEndClass && inView && animateEndClass,
        },
        className,
      )}
      {...other}
    >
      {children}
    </Wrapper>
  );
};

ScrollInView.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  delay: PropTypes.number,
  children: PropTypes.node.isRequired,
  triggerOnce: PropTypes.bool,
  animateStartClass: PropTypes.string,
  // tw`animate-in-up-start`
  // tw`animate-fade-in-up-start`
  // tw`animate-fade-in-start`
  animateEndClass: PropTypes.string,
  // tw`animate-in-up-end`
  // tw`animate-fade-in-up-end`
  // tw`animate-fade-in-end`
  as: PropTypes.string,
};

ScrollInView.defaultProps = {
  as: 'div',
  className: null,
  delay: null,
  triggerOnce: true,
  animateStartClass: null,
  animateEndClass: null,
};

export default ScrollInView;
