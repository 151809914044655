import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import './styles.module.scss';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  className,
  partiallyActive,
  styled,
  underline,
  internalExternal,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = () => {
    if (internalExternal) {
      if (to._type === 'externalLink') {
        return false;
      }
      return true;
    }
    return /^[/#](?!\/)/.test(to);
  };

  const renderUrl = () => {
    if (internalExternal) {
      return to._type === 'externalLink' || to._type === 'relativeLink'
        ? to.url
        : `/${to.page?.slug.current}`;
    }
    return to;
  };
  // Use Gatsby Link for internal links, and <a> for others
  if (internal()) {
    return (
      <GatsbyLink
        to={renderUrl()}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={cn(
          className,
          { link: styled && underline },
          { 'no-underline-link': styled && !underline },
        )}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a
      href={renderUrl()}
      className={cn(
        className,
        { link: styled && underline },
        { 'no-underline-link': styled && !underline },
      )}
      target="_blank"
      rel="noopener noreferrer"
      {...other}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  styled: PropTypes.bool,
  underline: PropTypes.bool,
  internalExternal: PropTypes.bool,
};

Link.defaultProps = {
  className: '',
  activeClassName: '',
  partiallyActive: false,
  styled: true,
  underline: true,
  internalExternal: false,
};

export default Link;
