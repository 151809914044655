import React from 'react';
import PropTypes from 'prop-types';

const ArrowUpTopIcon = ({ inset }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        width="32"
        height="32"
        d="M6 14L7.393 15.43L15 7.85L15 28L17 28L17 7.85L24.573 15.43L26 14L16 4L6 14Z"
        fill="#FDF5E3"
      />
    </svg>
  );
};

ArrowUpTopIcon.propTypes = {
  inset: PropTypes.bool,
};

ArrowUpTopIcon.defaultProps = {
  inset: false,
};
export default ArrowUpTopIcon;
