import React, { useRef, useState } from 'react';
import cn from 'classnames';
import ArrowUpTopIcon from '@icons/ArrowUpTopIcon';
import useScrollPosition from '@utility/useScrollPosition';
import styles from './styles';

const BackToTop = () => {
  const ref = useRef();
  const [showIcon, setShowIcon] = useState(false);
  useScrollPosition(
    ({ currPos }) => {
      if (ref.current) {
        if (currPos > 100) {
          setShowIcon(true);
        } else {
          setShowIcon(false);
        }
      }
    },
    [ref.current],
    0,
    true,
    50,
  );
  const buttonHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <button
      ref={ref}
      type="button"
      onClick={buttonHandler}
      className={cn(styles.buttonUp, {
        [styles.buttonUpShow]: showIcon,
      })}
    >
      <ArrowUpTopIcon />
    </button>
  );
};

export default BackToTop;
