import { useState } from 'react';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';
import debounce from 'lodash.debounce';

function getWindowDimensions() {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  return {
    windowWidth,
    windowHeight,
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: null,
    windowHeight: null,
  });

  useIsomorphicLayoutEffect(() => {
    setWindowDimensions(getWindowDimensions());

    const handleResize = debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, 500);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // console.log("windowDimensions", windowDimensions);

  return windowDimensions;
};

export default useWindowDimensions;
