import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '@utility/Link';
import styles from './styles';
import LogoWordmark from '../../icons/LogoWordmark';

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        header: allStoryblokEntry(
          filter: {
            published_at: { ne: null }
            field_component: { eq: "shoplocal_page" }
          }
        ) {
          edges {
            node {
              id
              uuid
              content
            }
          }
        }
      }
    `,
  );
  const headerData = data?.header?.edges[0]?.node?.content;
  const headerContent = JSON.parse(headerData);
  return (
    <header className={`wrapper h-full ${styles.header}`}>
      <div className="row md:py-4">
        <div className="col-12">
          <hr className="border-t-4 border-black md:mb-4" />
        </div>
        <div
          className={`py-4 type-6050 lg:type-10050 col-12 sm:col-8 md:col-6 ${styles.headerLeft}`}
        >
          <Link to="/" styled={false}>
            <LogoWordmark />
          </Link>
        </div>
        <div className="col-12 md:hidden">
          <hr className={`border-b-2 w-full ${styles.headerDivider}`} />
        </div>
        <div
          className={`col-9 type-2040 lg:type-2240 py-6 md:col-5 md:border-l-2 md:border-r-2 ${styles.headerRight}`}
        >
          <div className="flex border-r-2 md:border-r-0 md:justify-center md:self-center md:content-center md:mx-12">
            <h1 className="px-1">
              <span className="sr-only">{headerContent.title} - </span>
              {headerContent.description}
            </h1>
          </div>
        </div>
        <div className="col-3 py-6 md:py-0 md:col-1 flex flex-col justify-between md:justify-center md:self-center md:content-center">
          <div className="self-center type-1850 md:py-6">EST.</div>
          <hr
            className={`hidden md:block border-b-2  ${styles.headerDivider}`}
          />
          <div className="self-center type-1840 md:py-6">2020</div>
        </div>
        <div className="col-12">
          <hr className="border-t-2 border-black md:mt-4" />
        </div>
      </div>
    </header>
  );
};

export default Header;
