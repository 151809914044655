import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isSafariBrowser from '@helpers/isSafari';
import useWindowDimensions from '@utility/useWindowDimensions';
import { Parallax as ParallaxContainer } from 'react-scroll-parallax';
import styles from './styles';

const Parallax = ({ children, y, mobileY, x, className, contain }) => {
  const [isSafari, setIsSafari] = useState(true);
  const { windowWidth } = useWindowDimensions();
  const desktopBreakpoint = 768;
  useLayoutEffect(() => {
    if (isSafariBrowser() !== null) {
      setIsSafari(isSafariBrowser());
    }
  }, []);
  const renderParallax = () => {
    if (windowWidth) {
      return (
        <ParallaxContainer
          y={mobileY && windowWidth <= desktopBreakpoint ? mobileY : y}
          x={x}
          className={styles.parallax}
        >
          {children}
        </ParallaxContainer>
      );
    }
  };

  const renderContain = () =>
    contain ? (
      <div className={`overflow-hidden ${className}`}>{renderParallax()}</div>
    ) : (
      renderParallax()
    );

  return isSafari ? (
    <div className={`${styles.parallax} ${className}`}>{children}</div>
  ) : (
    renderContain()
  );
};

Parallax.propTypes = {
  children: PropTypes.node.isRequired,
  y: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  mobileY: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  x: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  className: PropTypes.string,
  contain: PropTypes.bool,
};

Parallax.defaultProps = {
  className: '',
  x: [0, 0],
  contain: true,
  mobileY: null,
};

export default Parallax;
