/**
 * Detect Safari Browsers
 * // Safari 3.0+ "[object HTMLElementConstructor]"
 */

export default function isSafariBrowser() {
  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
  return isSafari;
}
