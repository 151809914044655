/* eslint-disable */

/**
 * Implement Gatsby's Browser APIs in this file.
 * This file is where Gatsby expects to find any usage of the Gatsby browser APIs (if any).
 * These allow customization/extension of default Gatsby settings affecting the browser.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import Layout from './src/components/Layout';

require('./src/styles/global.scss');

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      <Layout {...props}>{element}</Layout>
    </>
  );
};
