/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';
import Cursor from '@components/Cursor';
import Header from '@components/Header';
import Footer from '@components/Footer';
import isSafariBrowser from '@helpers/isSafari';
import useWindowDimensions from '@utility/useWindowDimensions';
import ParallaxCache from '@utility/ParallaxCache';
import BackToTop from '@components/BackToTop';
import smoothscroll from 'smoothscroll-polyfill';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';

const Layout = ({ children }) => {
  const [isSafari, setIsSafari] = useState(true);
  const { windowWidth } = useWindowDimensions();
  useLayoutEffect(() => {
    if (isSafariBrowser() !== null) {
      setIsSafari(isSafariBrowser());
    }
  }, []);
  useIsomorphicLayoutEffect(() => {
    smoothscroll.polyfill();
  }, []);
  const renderChildren = () => (
    <div className="mt-5">
      <Header />
      <main>
        {children}
        <Cursor />
        <Footer />
      </main>
      <BackToTop />
    </div>
  );
  return (
    <>
      {isSafari ? (
        renderChildren()
      ) : (
        <ParallaxProvider>
          <>
            {renderChildren()}
            {windowWidth && <ParallaxCache />}
          </>
        </ParallaxProvider>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
