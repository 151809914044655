import React from 'react';
import Parallax from '@utility/Parallax';
import HeartIcon from '@icons/HeartIcon';
import Link from '@utility/Link';
import ScrollInView from '@utility/ScrollInView';
import LogoStamp from '@icons/LogoStamp';
import styles from './styles';

const Footer = () => (
  <footer className={` ${styles.footer}`}>
    <div className="wrapper">
      <div className="row py-12 md:pt-32">
        <div className="col-12 md:col-9 type-scaling-20040">
          <p>Proudly Canadian.</p>
        </div>
        <div className="hidden md:col-3 md:flex justify-end self-center">
          <LogoStamp />
        </div>
      </div>
      <div className="row md:py-5 type-1840 md:type-2240">
        <div className="col-12">
          <hr className="border-t-4 border-cream mb-5" />
        </div>
        <div className="col-12 md:col-6 py-5 border-cream md:border-r-2">
          <div className="pb-2">
            Made with <HeartIcon /> remotely by &nbsp;
            <Link
              to="https://www.enginedigital.com"
              styled={false}
              className="underline"
            >
              Engine Digital
            </Link>
          </div>

          <div>
            Want to get in touch?{' '}
            <Link
              to="mailto:info@nicelysmall.com"
              styled={false}
              className="underline"
            >
              Email us
            </Link>
          </div>
        </div>
        <div className="block md:hidden col-12">
          <div className="">
            <hr className="border-t-2 border-cream mt-5" />
          </div>
        </div>

        <div className="col-12 type-1840 md:type-2240 md:col-6 pt-10 md:py-6 flex md:justify-center md:self-center">
          &#169; 2020 NicelySmall.&nbsp;{' '}
          <div className="hidden md:block"> All rights reserved. </div>
        </div>

        <div className="col-12 type-1840 md:type-2240 md:col-6 md:hidden">
          {' '}
          All rights reserved.{' '}
        </div>
        <div className="hidden md:block col-12">
          <hr className="border-t-2 border-cream mt-5" />
        </div>
      </div>
      <div className="col-12 flex justify-center pt-10 md:hidden">
        <LogoStamp />
      </div>
    </div>
  </footer>
);

export default Footer;
