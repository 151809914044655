import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles';

const ArrowUpRightIcon = ({ inset, styled, className }) => {
  return (
    <svg
      className={cn([
        { [styles.iconArrowUp]: styled },
        { [styles.iconLink]: styled && !inset },
        { [styles.iconLinkInset]: styled && inset },
        className,
      ])}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0V2H16.59L0 18.59L1.41 20L18 3.41V16H20V0H4Z"
        fill="#010101"
        className="fill-current"
      />
    </svg>
  );
};

ArrowUpRightIcon.propTypes = {
  inset: PropTypes.bool,
  styled: PropTypes.bool,
  className: PropTypes.string,
};

ArrowUpRightIcon.defaultProps = {
  inset: false,
  styled: true,
  className: '',
};
export default ArrowUpRightIcon;
